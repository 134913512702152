function clickPortfolio(e) {
  if (window.innerWidth < 992) {
    e.preventDefault();
  } else {
  }
}

jQuery(document).ready(function ($) {
  $(".section__spacer").css("height", $(".section__header ").height() + 20);
  $(window).resize(function () {
    $(".section__spacer").css("height", $(".section__header ").height() + 20);
  });
  $(".burgerMobile, .overlay").click(function () {
    $(".overlay2").removeClass("show");
    if ($(".burgerMobile").hasClass("clicked")) {
      console.log(`is clicked`);
      $(".burgerMobile").removeClass("clicked");
      $(".overlay").removeClass("show");
      $("nav").removeClass("show");
      $("body").removeClass("overflow");
      $(".navAgain").removeClass("show");
    } else {
      console.log(`not clicked`);
      $(".burgerMobile").addClass("clicked");
      $(".overlay").addClass("show");
      $("nav").addClass("show");
      $("body").addClass("overflow");
      $(".navAgain").removeClass("show");
    }
  });
  $(".overlay").click(function () {});
  $(".btnCloseContacts, .overlay2").click(function () {
    $(".overlay2").toggleClass("show");
    $(".navAgain").toggleClass("show");
    $("body").toggleClass("overflow");
    if ($(".burgerMobile").hasClass("clicked")) {
      $("nav").css("display", "none");
      setTimeout(() => {
        $("nav").css("display", "block");
      }, 500);
      $(".burgerMobile").toggleClass("clicked");
      $(".overlay").toggleClass("show");
      $("nav").toggleClass("show");
      $("body").toggleClass("overflow");
    }
  });
  //set animation timing
  var animationDelay = 1500,
    //loading bar effect
    barAnimationDelay = 3800,
    barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
    //letters effect
    lettersDelay = 50,
    //type effect
    typeLettersDelay = 150,
    selectionDuration = 500,
    typeAnimationDelay = selectionDuration + 800,
    //clip effect
    revealDuration = 600,
    revealAnimationDelay = 1500;

  function beginAnimatedHeadline() {
    const $rows = $(".cd-headline.letters")
      .find("b")
      .filter((_, elem) => window.innerWidth > 992 ? true : !$(elem).hasClass("mobile-hide") );

    const rowLength = $rows.length;
    let currentRowIndex = 0;

    function singleLetters($words) {
      $words.each(function () {
        var word = $(this),
          letters = word.text().split(""),
          selected = word.hasClass("is-visible");
        for (const i in letters) {
          if (word.parents(".rotate-2").length > 0)
            letters[i] = "<em>" + letters[i] + "</em>";
          letters[i] = selected
            ? '<i class="in">' + letters[i] + "</i>"
            : "<i>" + letters[i] + "</i>";
        }
        var newLetters = letters.join("");
        word.html(newLetters).css("opacity", 1);
      });
    }

    function animateHeadline($headlines) {
      var duration = animationDelay;
      $headlines.each(function () {
        var headline = $(this);

        if (headline.hasClass("loading-bar")) {
          duration = barAnimationDelay;
          setTimeout(function () {
            headline.find(".cd-words-wrapper").addClass("is-loading");
          }, barWaiting);
        } else if (headline.hasClass("clip")) {
          var spanWrapper = headline.find(".cd-words-wrapper"),
            newWidth = spanWrapper.width() + 10;
          spanWrapper.css("width", newWidth);
        }
        //trigger animation
        setTimeout(function () {
          const $row = $rows.eq(currentRowIndex);
          hideWord($row);
        }, duration);
      });
    }

    function hideWord($word) {
      var $nextWord = takeNextRow($word);

      if ($word.parents(".cd-headline").hasClass("type")) {
        var parentSpan = $word.parent(".cd-words-wrapper");
        parentSpan.addClass("selected").removeClass("waiting");
        setTimeout(function () {
          parentSpan.removeClass("selected");
          $word
            .removeClass("is-visible")
            .addClass("is-hidden")
            .children("i")
            .removeClass("in")
            .addClass("out");
        }, selectionDuration);
        setTimeout(function () {
          showWord(nextWord, typeLettersDelay);
        }, typeAnimationDelay);
      } else if ($word.parents(".cd-headline").hasClass("letters")) {
        var isRowGreaterNextRow =
          $word.children("i").length >= $nextWord.children("i").length
            ? true
            : false;
        hideLetter($word.find("i").eq(0), $word, $nextWord, isRowGreaterNextRow, lettersDelay);
        showLetter($nextWord.find("i").eq(0), $nextWord, isRowGreaterNextRow, lettersDelay);
      } else if ($word.parents(".cd-headline").hasClass("clip")) {
        $word.parents(".cd-words-wrapper").animate(
          {
            width: "2px",
          },
          revealDuration,
          function () {
            switchWord($word, $nextWord);
            showWord($nextWord);
          }
        );
      } else if ($word.parents(".cd-headline").hasClass("loading-bar")) {
        $word.parents(".cd-words-wrapper").removeClass("is-loading");
        switchWord($word, $nextWord);
        setTimeout(function () {
          hideWord($nextWord);
        }, barAnimationDelay);
        setTimeout(function () {
          $word.parents(".cd-words-wrapper").addClass("is-loading");
        }, barWaiting);
      } else {
        switchWord($word, $nextWord);
        setTimeout(function () {
          hideWord($nextWord);
        }, animationDelay);
      }
    }

    function showWord($word, $duration) {
      if ($word.parents(".cd-headline").hasClass("type")) {
        showLetter($word.find("i").eq(0), $word, false, $duration);
        $word.addClass("is-visible").removeClass("is-hidden");
      } else if ($word.parents(".cd-headline").hasClass("clip")) {
        $word.parents(".cd-words-wrapper").animate(
          {
            width: $word.width() + 10,
          },
          revealDuration,
          function () {
            setTimeout(function () {
              hideWord($word);
            }, revealAnimationDelay);
          }
        );
      }
    }

    function hideLetter($letter, $word, $nextWord, isRowGreaterNextRow, $duration) {
      $letter.removeClass("in").addClass("out");

      if (!$letter.is(":last-child")) {
        setTimeout(function () {
          hideLetter($letter.next(), $word, $nextWord, isRowGreaterNextRow, $duration);
        }, $duration);
      } else if (isRowGreaterNextRow) {
        setTimeout(function () {
          hideWord($nextWord);
        }, animationDelay);
      }

      if (
        $letter.is(":last-child") &&
        $("html").hasClass("no-csstransitions")
      ) {
        switchWord($word, $nextWord);
      }
    }

    function showLetter($letter, $word, isRowGreaterNextRow, $duration) {
      $letter.addClass("in").removeClass("out");

      if (!$letter.is(":last-child")) {
        setTimeout(function () {
          showLetter($letter.next(), $word, isRowGreaterNextRow, $duration);
        }, $duration);
      } else {
        if ($word.parents(".cd-headline").hasClass("type")) {
          setTimeout(function () {
            $word.parents(".cd-words-wrapper").addClass("waiting");
          }, 200);
        }
        if (!isRowGreaterNextRow) {
          setTimeout(function () {
            hideWord($word);
          }, animationDelay);
        }
      }
    }
    
    function takeNextRow() {
      currentRowIndex = currentRowIndex < rowLength - 1
        ? currentRowIndex + 1
        : 0;

      const $nextRow = $rows.eq(currentRowIndex);
      return $nextRow;
    }

    function switchWord($oldWord, $newWord) {
      $oldWord.removeClass("is-visible").addClass("is-hidden");
      $newWord.removeClass("is-hidden").addClass("is-visible");
    }

    singleLetters($rows);
    //initialise headline animation
    animateHeadline($(".cd-headline"));
  }

  beginAnimatedHeadline();
});
